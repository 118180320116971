.light-candle-wrap {
  display: flex;
  position: relative;
  width: 100%;

  .candle-wrap {
    background: $primary;
    padding: 3rem 1rem;
    width: 100%;
    max-width: 224px;
  }

  .candle-section {
    width: 100%;
    justify-content: center;
    opacity: 0;
    display: none;
  }

  &.is-candle-active {
    .button {
      display: none;
    }

    .candle-section {
      display: flex;
      animation: fadeIn 0.6s;
      animation-fill-mode: forwards;
    }
  }
}

$flame: #ffad00;
$flame-shadow: #ffad00;
$wax: #f6e3bf;
$wax-shadow: darken(#f6e3bf, 7);
$wick: #ad87a9;
$stand: darken($primary, 15%);

.candle-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .candle {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-flame {
      width: 15px;
      height: 30px;
      background: $flame;
      border-radius: 8px 8px 8px 8px / 20px 20px 8px 8px;
      box-shadow: 0px 0px 20px 0px $flame-shadow;
      transform-origin: bottom;
      animation: flame-twirl 15s ease infinite, glow 2s ease infinite,
        flame-scale 0.2s ease infinite;
    }

    &-wick {
      height: 10px;
      width: 3px;
      background: $wick;
    }

    &-wax {
      width: 57px;
      height: 80px;
      background: $wax;
      border-radius: 6px;
      box-shadow: inset -15px 0px 0px -10px $wax-shadow;
    }

    &-stand {
      width: 100px;
      height: 8px;
      background: $stand;
      border-radius: 3px;
    }
  }
}

.candle-info {
  a {
    text-decoration: none !important;
  }
}

.candle__text {
  margin-top: 1rem;
  color: white;
  text-align: center;
  font-size: 0.875rem;

  a {
    color: white;

    &:hover {
      text-decoration: underline;
    }
  }
}

.soldier__bio {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.soldier__bio-candle-wrap {
  margin-left: 1.5rem;

  .candle-container {
    .candle-flame {
      width: 8px;
      height: 16px;
    }

    .candle-wick {
      height: 5px;
      width: 2px;
    }

    .candle-wax {
      width: 23px;
      height: 30px;
      border-radius: 4px 4px 4px 4px / 4px 4px 4px 4px;
    }

    .candle-stand {
      width: 40px;
      height: 4px;
      background: $primary;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes flame-scale {
  0% {
    transform: scale(0.99);
  }
  100% {
    transform: scale(1.01);
  }
}

@keyframes flame-twirl {
  0%,
  22%,
  49%,
  62%,
  81%,
  100% {
    border-radius: 2px 14px 8px 8px / 20px 20px 8px 8px;
  }
  14%,
  32%,
  56%,
  70%,
  89% {
    border-radius: 14px 2px 8px 8px / 20px 20px 8px 8px;
  }
}

@keyframes glow {
  0%,
  30%,
  60%,
  80%,
  100% {
    box-shadow: 0 0 20px 0 #ffad00;
  }
  20%,
  50%,
  70% {
    box-shadow: 0 0 22px 0 #ffad00;
  }
}

@media (min-width: 769px) {
  .light-candle-wrap {
    justify-content: flex-end;
  }
}
