// ======
// bulma
// ======

@import "~bulma/sass/utilities/initial-variables";

$primary: #29577a;
$link: $primary;

$radius-small: 0;
$radius: 0;
$radius-large: 0;

$navbar-height: 5rem;
$navbar-item-img-max-height: 4rem;
$navbar-item-hover-background-color: #234967; // its somehow derrived from primary color
$navbar-item-color: $white;
$navbar-item-hover-color: $white;
$navbar-background-color: rgba($primary, 0.7);

$card-shadow: 0 13px 26px rgba(0, 0, 0, 0.1);

@import "~bulma/bulma";

// ======
// components
// ======

@import "autocomplete";
@import "~glightbox/dist/css/glightbox.min.css";
@import "article_detail";
@import "light_candle";

// ======
// custom styles
// ======

.is-fullheight {
  height: 100%;
}

.zoom-on-hover {
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

// printing styles
// =============

@media print {
  .is-hidden-print {
    display: none !important;
  }
  .navbar-brand {
    img {
      filter: invert(1);
    }
  }
}

// =========
// push footer to the very bottom
// =========

.has-footer-bottom {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-content {
  flex: 1;
}

// navbar stuff
// ============

.navbar-burger {
  &:hover {
    // a:hover is by default dark grey and it's not visible enough
    color: $grey-lighter;
  }
}

// navbar over the content
.navbar-overlayed {
  position: absolute;
  width: 100%;
}

// dropdown menu
.navbar-start {
  > li {
    display: flex;
    justify-content: stretch;
  }
}

.navbar-submenu {
  display: none;
}

.cookies {
  opacity: 0.9;
  margin: 1rem;
  justify-content: flex-end;
  background-color: $primary;
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10000;

  a {
    padding: 0.9rem;
    color: inherit;
    &:hover {
      background-color: $navbar-item-hover-background-color;
    }
  }
}

.navbar-submenu-item {
  background-color: $navbar-item-hover-background-color;
  font-size: 14px;
  // stylelint-disable-next-line no-descending-specificity
  a {
    color: #ffffff;
    padding: 14px 17px;
    display: block;
  }

  &:hover,
  &:focus,
  &:focus-within {
    background-color: #bacdde;

    a {
      color: #2a455f;
    }
  }
}

.content,
.footer,
.logos {
  a:not(.button) {
    color: inherit;
    font-weight: $weight-semibold;
    text-decoration: underline;
  }
}

.navbar-item__submenu-icon {
  width: 12px;
  height: 8px;
  top: 15px;
  right: 35px;
  transform: rotate(-90deg);
  position: absolute;
  cursor: pointer;

  @media screen and (min-width: 1024px) {
    transform: rotate(0deg);
    top: calc(50% - 4px);
    right: 12px;
  }
}

.navbar-item__has-submenu {
  position: relative;

  @media screen and (min-width: 1024px) {
    flex-direction: initial;
  }

  .navbar-item {
    padding-right: 12px;
    display: inline-block;

    @media screen and (min-width: 1024px) {
      padding-right: 36px;
      flex-direction: initial;
      display: inherit;
    }
  }
}

.js-dropdown-visible,
.navbar-item__has-submenu:hover,
.navbar-item__has-submenu:focus,
.navbar-item__has-submenu:focus-within {
  flex-direction: column;
  background-color: $navbar-item-hover-background-color;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }

  .navbar-item__submenu-icon {
    transform: rotate(180deg);
    padding-top: 0;
  }

  .navbar-submenu {
    display: block;

    @media screen and (min-width: 1024px) {
      position: absolute;
      top: $navbar-height;
      left: 0;
      min-width: 200px;
      width: auto;
    }
  }
}

// hero displayed under overlayed navbar
.under-navbar {
  .hero-body {
    padding-top: $navbar-height + 2rem;
  }
}

// backgrounds
// ===========

.has-background-image {
  position: relative;
}

.background-image {
  height: 100%;
  left: 0;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.has-background-colorized {
  background-color: rgba($primary, 0.7);
  color: $white;

  h1,
  h2,
  strong {
    color: $white;
  }
}

// search form
// ===========

.search-form-container {
  position: absolute;
  width: 100%;
  z-index: 5;
  margin-bottom: 2rem;
}

.search-form {
  .input {
    border-color: #ced8e1;
    border-radius: 0;
    border-width: 0 0 2px;
    box-shadow: none;
    transition: all 0.2s;
    padding-left: 0;
    font-size: 1.25rem;

    &::placeholder {
      color: $primary;
      opacity: 1;
      font-weight: bold;
    }

    &:focus {
      border-color: $primary;

      &::placeholder {
        color: $grey-lighter;
      }
    }
  }
}

// tags
// ===========

.tags-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tags-trigger-label {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  &::after {
    content: "";
    background: url("../images/chevron-down.svg") no-repeat;
    background-position: right;
    width: 34px;
    height: 24px;
  }
  &.expanded {
    &::after {
      transform: rotate(180deg);
      background-position: left;
    }
  }
}
.tags-search {
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in;
  &.expanded {
    transition: max-height 0.4s ease-out;
    max-height: 99rem;
  }
}
.tags-group {
  width: 100%;
  &__title {
    color: $primary;
    font-weight: bold;
    margin: 0.5rem 0;
    width: 100%;
  }
}
.tags-search-tag {
  &:not(body) {
    color: $primary;
    font-weight: bold;
    font-size: 0.9rem;
    background-color: #ededed;
    cursor: pointer;
  }
  &__checkbox {
    display: none;
  }
  &__count {
    color: #6687a0;
    padding-left: 0.75rem;
  }

  &.selected {
    background-color: $primary;
    color: #ededed;
  }
}

.tags-reload-help {
  margin: 0.5rem auto 0;
  font-weight: bold;
  color: $primary;
  opacity: 0.5;
}

.search-results-form {
  margin-bottom: 2rem;
  .tags-trigger-label {
    opacity: 0.5;
  }
}

.button-uppercase {
  @include from($tablet) {
    letter-spacing: 0.15em;
    padding: 0 2em;
  }

  border-radius: 0;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

// ========
// soldier detail page
// ========

.soldier-images {
  @include from($tablet) {
    margin-top: -8rem;
  }
}

.logos {
  .column {
    &:not(:last-child) {
      @include from($tablet) {
        margin-right: 1rem;
      }
    }
  }
}

.sources-list {
  margin-left: 0 !important;
  li {
    display: inline;
  }
}

// definition list
// ===============

dt {
  font-weight: $weight-bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

dfn {
  font-style: unset;
  font-weight: $weight-bold;
}

// logos
// ========

.logo-orlen {
  img {
    vertical-align: middle;
    padding-right: 2rem;
    height: 3rem;
    border-right: 2px solid;
    border-color: inherit;
  }
  span {
    padding-left: 2rem;
    letter-spacing: 0.09rem;
  }
}

// cookies alert
// =============

.cookies-text {
  padding-bottom: 1rem;
}

.cookies-buttons {
  display: flex;
  justify-content: flex-end;
}

.weapon-article-card {
  .card {
    display: flex !important;
    flex-direction: column-reverse;
    padding-top: 0 !important;
  }

  .card-content {
    display: initial !important;
    height: auto !important;
    min-height: 190px;
    @media screen and (min-width: 1400px) {
      min-height: 240px;
    }
  }

  .has-background-colorized {
    background-color: $primary;
  }

  .background-image {
    position: static;
    top: auto;
    z-index: unset;
    max-height: 250px;

    @media screen and (min-width: 756px) {
      max-height: 300px;
    }
    @media screen and (min-width: 1200px) {
      max-height: 250px;
    }
  }
}

.content {
  p > img {
    padding: 1em;
    box-sizing: border-box;
  }
  figure {
    max-width: 45%;
    margin: 1em !important;
  }
}

.article-content {
  figure {
    &:last-of-type {
      margin: 1em auto !important;
    }
  }
}

.video {
  margin: 2em 0;
  iframe {
    height: 400px;
    width: 100%;
  }
}

.temporary-banner {
  display: block;
  margin-top: 8rem;

  .banner__img {
    width: 100%;
  }
}

.banner__content {
  color: white;
  max-width: 640px;
}

@media (min-width: 769px) {
  .temporary-banner {
    margin-top: 5rem;
  }
}
