// default styles taken from https://github.com/algolia/autocomplete.js/#look-and-feel

.algolia-autocomplete {
  width: 100%;

  .aa-input,
  .aa-hint {
    width: 100%;
  }

  .aa-hint {
    color: #999;
  }

  .aa-dropdown-menu {
    background-color: #fff;
    border-top: 0;
    border: 1px solid #999;
    width: 100%;

    .aa-suggestion {
      cursor: pointer;
      padding: 5px 4px;

      &.aa-cursor {
        background-color: #b2d7ff;
      }

      em {
        font-style: normal;
        font-weight: bold;
      }
    }
  }
}
